$ct-series-colors: (
        #00bcd4,
        #f05b4f,
        #f4c63d,
        #d17905,
        #453d3f,
        #59922b,
        #0544d3,
        #6b0392,
        #f05b4f,
        #dda458,
        #eacf7d,
        #86797d,
        #b2c326,
        #6188e2,
        #a748ca
) !default;

.ct-legend {
    position: relative;
    z-index: 10;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 23px;
        margin-bottom: 3px;
        margin-left: 10px;
        display: inline-block;
    }

    li:before {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 5px;
        top: 4px;
        content: '';
        border: 3px solid transparent;
        border-radius: 2px;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
         position: absolute;
         top: 0;
         right: 0;
     }

    @for $i from 0 to length($ct-series-colors) {
        .ct-series-#{$i}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
    }
}